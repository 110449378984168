@import '../../assets/styles/colors';
@import '../../assets/styles/fontMixins';
@import '../../assets/styles/forms';

.table {
  flex: 1;
  align-self: stretch;
  padding: 15px;
}

.inactive {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: inline;
}

:global {
  .ant-table-row:hover {
    :local {
      .inactive {
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.spinContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 50px 20px;
}

.tableHeading {
  font-size: 17px;
  margin: 15px 0;

  span {
    font-weight: 700;
  }
}
