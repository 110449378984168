.container {
  width: 95%;
  margin: 0 auto;
}

.tablewrapper {
  max-width: 100%;
  overflow: auto;
}

.spinContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainHeading {
  text-align: center;
  font-size: 42px;
}
.heading {
  margin: 15px;

  .time {
    font-size: 18px;
    margin-right: 25px;
    color: #000;
  }

  .transactionId {
    font-size: 15px;
  }
}
