@import '../../assets/styles/colors';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $elephant;

  .logo {
    max-width: 250px;
    padding: 10px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &.home {
    background-color: transparent;
    z-index: 4;

    .menu {
      border-bottom: none;
    }
  }

  @media only screen and (max-width: 1280px) {
    padding: 0;
  }
}

.menu {
  display: flex;
  align-items: center;
  line-height: 64px;
  background: transparent;
  overflow: hidden;
}

:global {
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
    text-transform: uppercase;
    font-size: 16px;
    padding: 0;
    margin: 0 20px;

    a {
      line-height: 2;
      color: $white;
    }

    &.ant-menu-item-selected {
      background-color: transparent;
      border-bottom: 1px solid $picton-blue;
    }

    &:hover {
      border-bottom: 1px solid $picton-blue;
      box-sizing: border-box;
    }

    @media only screen and (max-width: 1280px) {
      margin: 0 10px;
      font-size: 12px;

      .ant-btn-danger span {
        display: none;
      }
    }
  }
}
