@import "../../assets/styles/colors";
@import "../../assets/styles/fontMixins";

.root {
  min-height: 100vh;
  height: 100%;
  background: $white;
}
.header {
  background: $elephant;
}
.menu {
  line-height: 64px;
  background: $elephant;
}

.logout {
  float: right;
}

.content {
  display: flex;
  justify-content: center;
  align-items: stretch;
  font-size: 2em;

  &.home {
    margin-top: -64px;
  }
}

.footer {
  text-align: right;
  background: $white;

  ul {
    @include myriad-pro-regular;
    margin: 0;
    padding: 0;

    & > li {
      display: inline-block;
      margin: 0 20px;

      a {
        color: $picton-blue;

        &:focus {
          text-decoration: none;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
